import { RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Game",
    component: () =>
      import(/* webpackChunkName: "game" */ "../views/GameView.vue"),
  },
];

export default routes;
